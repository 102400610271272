import axios from 'axios';
import inherits from 'inherits';
import { Buffer } from 'buffer';

// Assign it to the global window object
window.inherits = inherits;

// Optional: Assign util methods if you need them
window.Buffer = Buffer;

window.axios = axios;
window.inherits = inherits;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


// Polyfill
if (typeof window.TextEncoder === 'undefined') {
    window.TextEncoder = require('util').TextEncoder;
}
